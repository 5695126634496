<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">

      <table>


        <tr>
          <th>Carrier Name</th>
          <th>Service Names</th>
          <th>Transit Days</th>
          <th>Window Time</th>
          <th>Logo</th>
          <th>Document</th>
          <th>Action</th>
        </tr>
        <tr v-for="(carrier, i) in carriers" :key="i">
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" placeholder="Enter Character Limit 30" :maxlength="max2"
                :disabled="!carrier.is_edit_mode" v-model="carrier.name" />
            </div>
          </td>
          <td>
            <div class="multychk_wrap">
              <div class=" label_and_element_wrapper" v-if="carrier.servicenames"
                v-for="(service, i) in carrier.servicenames" :key="'service' + i">
                <input type="text" v-model="service.name" placeholder="Service name" :maxlength="max2"
                  :disabled="!carrier.is_edit_mode" />
              </div>
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <input type="text" v-model="carrier.default_transit_days" placeholder="Transit Days" :maxlength="max2"
                :disabled="!carrier.is_edit_mode" />
            </div>
          </td>
          <td>
            <div v-if="carrier.window_schedule">
              {{ carrier.window_schedule.from }} - {{ carrier.window_schedule.to }}
            </div>
            <div v-else>
              Not yet set (default will be 8:00 - 17:00)
            </div>
          </td>
          <td>
            <div class="label_and_element_wrapper">
              <img :src="carrier.logo" />
              <label class="user_profile_upload" v-if="carrier.is_edit_mode">
                <span>Choose a File</span>
                <input type="file" accept=".jpg, .PNG" @change="uploadLogo(i)" :ref="'carrier_logo_' + i"
                  :disabled="!carrier.is_edit_mode" />
              </label>
            </div>
          </td>
          <td>
            <!--  <a class="table_action_btn" title="VIEW" >VIEW</a>
              <a  class="table_action_btn"   title="VIEW"  >VIEW</a> -->
            <a class="table_action_btn" :href="carrier.sla" target="_blank" v-if="carrier.is_sla" title="VIEW">Special
              Notes</a>

            <a class="table_action_btn" :href="carrier.terms" target="_blank" v-if="carrier.is_terms"
              title="VIEW">Terms</a>
          </td>
          <td class>
            <a class="table_action_btn" title="EDIT" @click="provider_id = carrier.id, is_editable = true">EDIT</a>
            <a class="table_action_btn" title="DELETE" @click="deleteCarrier(carrier.id)">DELETE</a>
            <a class="table_action_btn" title="VIEW" @click="provider_id = carrier.id, is_editable = false"> VIEW</a>
          </td>
        </tr>
      </table>
    </div>
    <div class="black-overlay" v-if="provider_id">
      <div class="box-modal" style="background:gold;">
        <div class="body">
          <!-- :provider_id="provider_id" -->
          <CarrierPopup v-if="provider_id" :provider_id="provider_id" @closeModal="fetchCarrier"
            :is_editable="is_editable" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CarrierPopup from "./CarrierEdit_Popup.vue";

export default {
  name: "Carriers",
  components: {
    CarrierPopup
  },
  data() {
    return {
      max: 70,
      max2: 30,
      dispatches: [],
      carriers: [],
      provider_id: ""
    };
  },
  created() {
    this.fetchCarrier();
    // this.fetchModeOfDispatch();
  },
  methods: {
    fetchCarrier() {
      this.provider_id = '';
      this.axios
        .get("/api/provider/")
        .then(response => {

          this.carriers = response.data.providers.map(element => {
            element.is_edit_mode = false;

            element.is_sla = element.sla.includes('SLA');
            element.is_terms = element.terms.includes('terms');

            element.is_available = element.is_available ? element.is_available : 0;
            element.is_carrier_preference = element.is_carrier_preference ? element.is_carrier_preference : 0;
            element.transit_type = element.transit_type == 'country' ? 'Country' : element.transit_type == 'postcodes' ? 'Post Codes' : element.transit_type == 'suburbs' ? 'Suburbs' : element.transit_type == 'state' ? 'State' : '';
            element.logo_img = element.logo;
            element.modes = element.modes && element.modes.length > 0 ? element.modes : "";
            element.servicenames = element.servicenames && element.servicenames.length > 0 ? element.servicenames : "";
            element.servicetypes = element.servicetypes && element.servicetypes.length > 0 ? element.servicetypes : "";
            element.goods = element.carriergoods && element.carriergoods.length > 0 ? element.carriergoods : "";

            return element;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchTypeOfGoods() {
      this.axios
        .get("/api/provider/")
        .then(async (response) => {
          let mode_of_dispatch = await this.axios.get("api/dispatch");
          this.goods = response.data.goods.map(good => {
            good.is_edit_mode = false;
            good.modes = mode_of_dispatch.data.mode_of_dispatch.map(element => {
              return {
                id: element.id,
                name: element.name,
                checked: good.modes.find(goodelement => goodelement.id == element.id) ? element.id : false
              }
            });
            return good;
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    fetchModeOfDispatch() {
      this.axios
        .get("/api/dispatch/")
        .then(response => {
          this.dispatches = response.data.mode_of_dispatch;
        })
        .catch(error => {
          console.log(error);
        });
    },
    uploadLogo(index) {
      this.carriers[index].logo = this.$refs[
        "carrier_logo_" + index
      ][0].files[0];
      this.carriers[index].logo_img = URL.createObjectURL(
        this.$refs["carrier_logo_" + index][0].files[0]
      );
    },
    updateCarrier(carrier) {
      if (carrier.logo.name) {
        let data = new FormData();
        Object.keys(carrier).forEach(element => {
          data.append(element, carrier[element]);
        });
        data.append("mode_of_dispatch[0]", carrier.mode);
        data.append("_method", "PUT");
        this.axios
          .post("/api/provider/" + carrier.id, data)
          .then(response => {
            this.toast.success(response.data.msg);
            carrier.is_edit_mode = false;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        let data1 = new FormData();
        Object.keys(carrier).forEach(element => {
          // data1.append(element, carrier[element]);
          data1.append("_method", "PUT");
          data1.append("name", carrier.name);
          data1.append("carrier_code", carrier.carrier_code);
          data1.append("description", carrier.description);
          data1.append("is_available", carrier.is_available);
          data1.append("mode_of_dispatch[0]", carrier.mode);
        });
        this.axios
          .post("/api/provider/" + carrier.id, data1)
          .then(response => {
            this.toast.success(response.data.msg);
            carrier.is_edit_mode = false;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    deleteCarrier(id) {
      if (confirm("Are you sure you want to delete this Carrier ?")) {
        this.axios
          .delete("/api/provider/" + id)
          .then(response => {
            this.toast.success(response.data.msg);
            this.fetchCarrier();
          })
          .catch(error => {
            console.log("error", error.response);
            this.toast.error();
          });
      }
    },
    cancelCarrier() {
      this.fetchCarrier();
    }
  }
};
</script>
<style  scoped >
.scoped_cover_div {
  margin: 0;
}

@media screen and (max-width: 1600px) {
  .scoped_cover_div {
    min-width: 1400px;
    padding-right: 20px;
  }
}

.scoped_cover_div section {
  padding: 0;
}

.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}

.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06A5ED;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}

.righted {
  text-align: right;
}

.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}

.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}

.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}

.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}</style>